import styled from 'styled-components/macro';

export default styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 30px;

  .spinner {
    width: 100%;
  }
`;
